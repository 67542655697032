
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { displayMomentAtDateTime } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import { Exception } from '@sentry/types'
import moment from 'moment'
import QRCode from 'qrcode'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { exhaustiveCheck } from 'smartbarcode-web-core/src/utils/helpers'
import { IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {
    IconVertialThreeDotCircle,
  },
  emits: ['onExportBarcode', 'onDeleteDraftProject', 'onDownloadWebclip'],
  name: 'ProjectItem',
})
export default class ProjectItem extends Vue {
  @Prop({ type: Object }) readonly project!: IProject

  dataUrl = ''

  get convertedDate() {
    return displayMomentAtDateTime(
      moment(this.project?.lastUpdatedDateTime || '')
        .local()
        .locale(this.$root.$i18n.locale)
    )
  }

  get isDraft(): boolean {
    return this.project?.isDraft ?? false
  }

  get isDisplayBarcodePublish() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_PUBLISH)
  }

  get entitledPortalUIViews() {
    return this.loggedUser?.entitledPortalUIViews || {}
  }

  get loggedUser() {
    return this.$store.state.profile?.user
  }

  created() {
    this.generateQR()
  }

  async gotoDetail() {
    this.$router.push({
      name: this.project?.isDraft ? 'projectDraft' : 'projectEdit',
      params: {
        version: this.project?.version,
        code: this.project?.isDraft ? this.project.id : this.project?.code,
      },
    })
  }

  async generateQR() {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${this.project?.code}@${this.project?.version}`
    this.dataUrl = await QRCode.toDataURL(url)
  }

  async onClick(action: 'export' | 'publish' | 'downloadWebclip') {
    if (this.project?.isDraft) {
      await this.deleteDraft()
    } else {
      switch (action) {
        case 'export':
          this.$emit('onExportBarcode', this.project)
          break
        case 'publish':
          this.$router.push({
            name: 'barcodePublish',
            query: {
              projectCode: this.project?.code,
            },
          })
          break
        case 'downloadWebclip':
          this.$emit('onDownloadWebclip', this.project)
          break
        default:
          exhaustiveCheck(action)
      }
    }
  }

  async deleteDraft() {
    this.$confirm(this.$t('projects.delete_draft_project'), this.$t('info'), {
      confirmButtonText: this.$t('delete'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        this.$emit('onDeleteDraftProject', this.project?.id)
      })
      .catch((e: string | Exception) => {
        if (e === 'cancel') return
        openMessage(e.toString(), 'error')
      })
  }
}
