<template>
  <SvgBase width="38" height="38">
    <path
      d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05888 28.9411 1 19 1C9.05888 1 1 9.05888 1 19C1 28.9411 9.05888 37 19 37Z"
      stroke="#9E9E9E"
    />
    <path
      d="M19.0011 12.757C20.2499 12.757 21.2624 11.7446 21.2624 10.4957C21.2624 9.2468 20.2499 8.23438 19.0011 8.23438C17.7522 8.23438 16.7397 9.2468 16.7397 10.4957C16.7397 11.7446 17.7522 12.757 19.0011 12.757Z"
      fill="#989898"
    />
    <path
      d="M19.0011 21.257C20.2499 21.257 21.2624 20.2446 21.2624 18.9957C21.2624 17.7468 20.2499 16.7344 19.0011 16.7344C17.7522 16.7344 16.7397 17.7468 16.7397 18.9957C16.7397 20.2446 17.7522 21.257 19.0011 21.257Z"
      fill="#989898"
    />
    <path
      d="M19.0011 29.675C20.2499 29.675 21.2624 28.6625 21.2624 27.4137C21.2624 26.1648 20.2499 25.1523 19.0011 25.1523C17.7522 25.1523 16.7397 26.1648 16.7397 27.4137C16.7397 28.6625 17.7522 29.675 19.0011 29.675Z"
      fill="#989898"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconVertialThreeDotCircle extends Vue {}
</script>
