<template>
  <div class="flex items-center gap-4 header-container" v-loading.fullscreen="loading">
    <div class="flex-grow items-center flex header-container__left">
      <PageHeader :title="$t('Projects')" @load:refreshList="fetchProjectsList" />
    </div>
    <div class="header-container__right">
      <el-button type="primary" icon="el-icon-plus" @click="onCreateProject">{{ $t('projects.newProject') }}</el-button>
    </div>
  </div>

  <div v-show="getProjectsList.length > 0" class="flex flex-wrap gap-5 my-6 table-container">
    <ProjectItem
      v-for="project in getProjectsList"
      :key="project"
      :project="project"
      @onDeleteDraftProject="onDeleteDraft"
      @onExportBarcode="onSelectProject($event, 'export')"
      @onDownloadWebclip="onSelectProject($event, 'webclip')"
    />
  </div>
  <ItemNotFound v-if="!loading && getProjectsList.length == 0" class="mt-56" :content="$t('projects.not_found')" />
  <el-dialog
    :custom-class="'el-dialog--custom'"
    v-model="isShowDialog"
    top="0"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="onCloseDialog"
  >
    <el-form
      v-if="dialogMode === 'export'"
      status-icon
      :label-position="'right'"
      label-width="220px"
      :rules="rules"
      :model="dateDataModel"
      ref="exportDateForm"
    >
      <div class="flex justify-evenly">
        <div class="self-center">{{ $t('export_date') }}</div>
        <div class="search-date-container">
          <el-date-picker
            :disabledDate="disabledDate"
            :clearable="false"
            v-model="dateDataModel.year"
            type="month"
            :editable="false"
          />
        </div></div
    ></el-form>
    <div v-else class="flex justify-evenly">
      <div class="self-center">{{ $t('projects.version') }}</div>
      <el-select v-model="webclipTargetProjectVersion" class="search-date-container">
        <el-option v-for="idx in currentSelectProject.version" :key="idx" :value="idx" :label="idx">{{
          idx
        }}</el-option>
      </el-select>
    </div>

    <template #title>
      {{ `${dialogMode === 'export' ? $t('projects.export_barcode') : $t('projects.webclip_target_project_version')}` }}
    </template>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="isShowDialog = false">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button
            type="primary"
            @click="dialogMode === 'export' ? onExportBarcode() : onDownloadWebclip(webclipTargetProjectVersion)"
          >
            {{ $t('timetable.process') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import ProjectItem from '@/components/common/ProjectItem.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import LoziLogo from '@/components/svg/LoziLogo.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { LOAD_PROJECTS_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { deleteDraftProject, downloadWebclip, exportBarCode } from '@/utils/api'
import { WEB_CLIP_ICON, WEB_CLIP_ICON_NAME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { isAfterDate, openMessage } from '@/utils/utils'
import { maska } from 'maska'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { IDownloadWebclipPayload, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
@Options({
  components: { SmartBarcodeLogo, LoziLogo, ProjectItem, ItemNotFound, PageHeader },
  directives: { maska },
  name: ESmartBarcodeMenu.PROJECT,
})
export default class Project extends mixins(ValidateForm) {
  isShowDialog = false
  loading = false
  currentSelectProject: IProject = {} as IProject
  dateDataModel: Record<string, string> = { year: moment().toString() }

  rules = { year: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }] }
  webclipTargetProjectVersion = 1

  get getProjectsList(): IProject[] {
    return [...this.$store.state.project.projects]
  }

  async onDeleteDraft(id: string) {
    try {
      this.loading = true
      await deleteDraftProject(id || '')
      await this.$store.dispatch(LOAD_PROJECTS_LIST)
      openMessage(this.$t('save_successful'), 'success')
    } catch (e) {
      const error = e as string
      if (error === 'cancel') return
      openMessage(error, 'error')
    } finally {
      this.loading = false
    }
  }

  async fetchProjectsList() {
    this.loading = true
    await this.$store.dispatch(LOAD_PROJECTS_LIST).finally(() => (this.loading = false))
  }

  disabledDate(date: Date) {
    return isAfterDate(date)
  }

  dialogMode: 'export' | 'webclip' = 'export'

  onSelectProject(project: IProject, mode: 'export' | 'webclip') {
    this.dialogMode = mode
    this.currentSelectProject = project
    this.webclipTargetProjectVersion = this.currentSelectProject.version || 1
    this.isShowDialog = true
  }

  onCloseDialog() {
    this.isShowDialog = false
    this.currentSelectProject = {} as IProject
  }

  validated(): boolean {
    if (this.dateDataModel.year) return true
    openMessage(this.$t('validate_occur'), 'error')
    return false
  }

  onChangeMonth(month: number) {
    return month.toString().length < 2 ? '0' + month.toString() : month.toString()
  }

  async onExportBarcode() {
    if (!this.validated()) return
    const month = this.onChangeMonth(new Date(this.dateDataModel.year).getMonth() + 1)
    const payload = {
      projectCode: this.currentSelectProject?.code || '',
      targetMonth: `${new Date(this.dateDataModel.year).getFullYear()}-${month}`,
    }
    this.loading = true
    await exportBarCode(payload)
      .then(() => this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true }))
      .catch((e) => errorHandler(e))
      .finally(() => {
        this.loading = false
        this.onCloseDialog()
      })
  }

  async onDownloadWebclip(projectVersion: number) {
    this.loading = true
    try {
      const payload: IDownloadWebclipPayload = {
        projectCode: this.currentSelectProject.code ?? '',
        projectVersion,
        icon: WEB_CLIP_ICON,
        label: WEB_CLIP_ICON_NAME,
      }
      const response = await downloadWebclip(payload)
      if (!response) return

      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${this.currentSelectProject.code}@${projectVersion}.mobileconfig`
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      errorHandler(e)
    } finally {
      this.loading = false
      this.onCloseDialog()
    }
  }

  onCreateProject() {
    this.$router.push({ name: 'projectCreate' })
  }

  async activated() {
    if (!this.$store.state.project.isLoaded) {
      await this.fetchProjectsList()
    }
  }

  async created() {
    if (!this.$store.state.project.isLoaded) {
      await this.fetchProjectsList()
    }
  }
}
</script>
<style lang="scss" scoped>
.search-date-container:deep() {
  cursor: pointer !important;
  .el-date-editor.el-input {
    width: 100%;
    text-align-last: center;
  }
}
</style>
