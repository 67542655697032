<template>
  <div class="bg-white project-item p-3 rounded-lg w-56">
    <div class="project-item__header rounded-md flex items-center p-3 h-14">
      <div class="flex-grow truncate leading-normal">
        <el-tooltip class="item" effect="dark" :content="project.name" placement="top">
          <h4 class="font-bold truncate leading-normal">
            {{ project.name }}
          </h4>
        </el-tooltip>

        <p class="leading-normal mt-1">
          {{ project.code }}
        </p>
      </div>

      <div>
        <span
          v-if="project.isDraft"
          class="bg-gray-800 rounded-full h-14 w-14 flex items-center justify-center text-white whitespace-nowrap"
        >
          {{ $t('draft') }}
        </span>
      </div>
    </div>
    <div class="flex justify-end">
      <div class="my-4">
        <img :src="dataUrl" class="w-36" />
      </div>
      <el-popover placement="bottom-end" :width="200" trigger="hover" popper-class="popover-barcode-list">
        <template #reference>
          <div class="w-8 h-8 my-8 mx-1 cover-svg-icon">
            <IconVertialThreeDotCircle class="" />
          </div>
        </template>
        <div
          @click="onClick('export')"
          :class="['cursor-pointer text-center', project?.isDraft ? 'text-red-400' : 'text-black']"
        >
          {{ $t(`projects.${project?.isDraft ? 'delete_draft_project_button' : 'export_barcode'}`) }}
        </div>

        <div v-if="!project?.isDraft">
          <div v-if="isDisplayBarcodePublish" class="border-solid border-b-1 border-gray-300 my-2"></div>

          <div
            v-if="isDisplayBarcodePublish"
            @click="onClick('publish')"
            :class="['cursor-pointer text-center', project?.isDraft ? 'text-red-400' : 'text-black']"
          >
            {{ $t('barcodeImport.barcodeImport') }}
          </div>
          <div
            @click="onClick('downloadWebclip')"
            :class="['cursor-pointer text-center', project?.isDraft ? 'text-red-400' : 'text-black']"
          >
            <div class="border-solid border-b-1 border-gray-300 my-2"></div>
            {{ $t('projects.download_webclip') }}
          </div>
        </div>
      </el-popover>
    </div>

    <div class="flex items-center my-3">
      <div class="flex-grow text-xs">
        {{ $t('projects.trackPointsCount') }}
      </div>
      <div>
        <span class="bg-gray-200 px-4 rounded-md font-bold">
          {{ project.trackingPointCount }}
        </span>
      </div>
    </div>

    <div class="flex items-center my-3">
      <div class="flex-grow text-xs">
        {{ $t('projects.barcodeTypeCount') }}
      </div>
      <div>
        <span class="bg-gray-200 px-4 rounded-md font-bold">
          {{ project.barcodeTypeCount }}
        </span>
      </div>
    </div>

    <div class="border-t border-solid border-gray-400 text-xs py-3">
      <div>{{ $t('projects.lastUpdateUser') }} : {{ project.lastUpdatedBy }}</div>
      <div class="mt-2">{{ $t('projects.LastUpdateDate') }} : {{ convertedDate }}</div>
    </div>

    <div class="mt-3 flex items-end">
      <el-button @click="gotoDetail" type="primary" class="ml-auto">
        <div class="action-button">
          {{ $t('projects.detail') }}
        </div>
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { displayMomentAtDateTime } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import { Exception } from '@sentry/types'
import moment from 'moment'
import QRCode from 'qrcode'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { exhaustiveCheck } from 'smartbarcode-web-core/src/utils/helpers'
import { IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
@Options({
  components: {
    IconVertialThreeDotCircle,
  },
  emits: ['onExportBarcode', 'onDeleteDraftProject', 'onDownloadWebclip'],
  name: 'ProjectItem',
})
export default class ProjectItem extends Vue {
  @Prop({ type: Object }) readonly project!: IProject

  dataUrl = ''

  get convertedDate() {
    return displayMomentAtDateTime(
      moment(this.project?.lastUpdatedDateTime || '')
        .local()
        .locale(this.$root.$i18n.locale)
    )
  }

  get isDraft(): boolean {
    return this.project?.isDraft ?? false
  }

  get isDisplayBarcodePublish() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_PUBLISH)
  }

  get entitledPortalUIViews() {
    return this.loggedUser?.entitledPortalUIViews || {}
  }

  get loggedUser() {
    return this.$store.state.profile?.user
  }

  created() {
    this.generateQR()
  }

  async gotoDetail() {
    this.$router.push({
      name: this.project?.isDraft ? 'projectDraft' : 'projectEdit',
      params: {
        version: this.project?.version,
        code: this.project?.isDraft ? this.project.id : this.project?.code,
      },
    })
  }

  async generateQR() {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    const url = `${appHost}/${this.project?.code}@${this.project?.version}`
    this.dataUrl = await QRCode.toDataURL(url)
  }

  async onClick(action: 'export' | 'publish' | 'downloadWebclip') {
    if (this.project?.isDraft) {
      await this.deleteDraft()
    } else {
      switch (action) {
        case 'export':
          this.$emit('onExportBarcode', this.project)
          break
        case 'publish':
          this.$router.push({
            name: 'barcodePublish',
            query: {
              projectCode: this.project?.code,
            },
          })
          break
        case 'downloadWebclip':
          this.$emit('onDownloadWebclip', this.project)
          break
        default:
          exhaustiveCheck(action)
      }
    }
  }

  async deleteDraft() {
    this.$confirm(this.$t('projects.delete_draft_project'), this.$t('info'), {
      confirmButtonText: this.$t('delete'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        this.$emit('onDeleteDraftProject', this.project?.id)
      })
      .catch((e: string | Exception) => {
        if (e === 'cancel') return
        openMessage(e.toString(), 'error')
      })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.project-item {
  &__header {
    background-color: $primary-60;
  }
}
</style>
