
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import ProjectItem from '@/components/common/ProjectItem.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import LoziLogo from '@/components/svg/LoziLogo.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { LOAD_PROJECTS_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { deleteDraftProject, downloadWebclip, exportBarCode } from '@/utils/api'
import { WEB_CLIP_ICON, WEB_CLIP_ICON_NAME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { isAfterDate, openMessage } from '@/utils/utils'
import { maska } from 'maska'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { IDownloadWebclipPayload, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
@Options({
  components: { SmartBarcodeLogo, LoziLogo, ProjectItem, ItemNotFound, PageHeader },
  directives: { maska },
  name: ESmartBarcodeMenu.PROJECT,
})
export default class Project extends mixins(ValidateForm) {
  isShowDialog = false
  loading = false
  currentSelectProject: IProject = {} as IProject
  dateDataModel: Record<string, string> = { year: moment().toString() }

  rules = { year: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }] }
  webclipTargetProjectVersion = 1

  get getProjectsList(): IProject[] {
    return [...this.$store.state.project.projects]
  }

  async onDeleteDraft(id: string) {
    try {
      this.loading = true
      await deleteDraftProject(id || '')
      await this.$store.dispatch(LOAD_PROJECTS_LIST)
      openMessage(this.$t('save_successful'), 'success')
    } catch (e) {
      const error = e as string
      if (error === 'cancel') return
      openMessage(error, 'error')
    } finally {
      this.loading = false
    }
  }

  async fetchProjectsList() {
    this.loading = true
    await this.$store.dispatch(LOAD_PROJECTS_LIST).finally(() => (this.loading = false))
  }

  disabledDate(date: Date) {
    return isAfterDate(date)
  }

  dialogMode: 'export' | 'webclip' = 'export'

  onSelectProject(project: IProject, mode: 'export' | 'webclip') {
    this.dialogMode = mode
    this.currentSelectProject = project
    this.webclipTargetProjectVersion = this.currentSelectProject.version || 1
    this.isShowDialog = true
  }

  onCloseDialog() {
    this.isShowDialog = false
    this.currentSelectProject = {} as IProject
  }

  validated(): boolean {
    if (this.dateDataModel.year) return true
    openMessage(this.$t('validate_occur'), 'error')
    return false
  }

  onChangeMonth(month: number) {
    return month.toString().length < 2 ? '0' + month.toString() : month.toString()
  }

  async onExportBarcode() {
    if (!this.validated()) return
    const month = this.onChangeMonth(new Date(this.dateDataModel.year).getMonth() + 1)
    const payload = {
      projectCode: this.currentSelectProject?.code || '',
      targetMonth: `${new Date(this.dateDataModel.year).getFullYear()}-${month}`,
    }
    this.loading = true
    await exportBarCode(payload)
      .then(() => this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true }))
      .catch((e) => errorHandler(e))
      .finally(() => {
        this.loading = false
        this.onCloseDialog()
      })
  }

  async onDownloadWebclip(projectVersion: number) {
    this.loading = true
    try {
      const payload: IDownloadWebclipPayload = {
        projectCode: this.currentSelectProject.code ?? '',
        projectVersion,
        icon: WEB_CLIP_ICON,
        label: WEB_CLIP_ICON_NAME,
      }
      const response = await downloadWebclip(payload)
      if (!response) return

      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${this.currentSelectProject.code}@${projectVersion}.mobileconfig`
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      errorHandler(e)
    } finally {
      this.loading = false
      this.onCloseDialog()
    }
  }

  onCreateProject() {
    this.$router.push({ name: 'projectCreate' })
  }

  async activated() {
    if (!this.$store.state.project.isLoaded) {
      await this.fetchProjectsList()
    }
  }

  async created() {
    if (!this.$store.state.project.isLoaded) {
      await this.fetchProjectsList()
    }
  }
}
